import gql from "graphql-tag";
import { find, map } from "lodash";
import React from "react";
import { Query } from "react-apollo";
import Select from "react-select";
import { connectionToArray } from "../../utils/graphql";
import styles from "./styles.module.scss";

export const SelectHost = ({ onSelect, selectedValue, label }) => {
  const HostsQuery = gql`
    query getHosts {
      hosts {
        edges {
          node {
            id
            fullName
            email
          }
        }
      }
    }
  `;
  return (
    <Query query={HostsQuery}>
      {({ data, loading }) => {
        if (loading) {
          return <span>Loading</span>;
        }
        const hosts = map(connectionToArray(data.hosts), host => {
          return { value: host.id, label: host.fullName };
        });
        let selectedHost = find(hosts, host => host.value === selectedValue);
        return (
          <div className="field">
            <label className={styles.label}>{label}</label>
            <div className="control">
              <Select value={selectedHost} onChange={onSelect} options={hosts} />
            </div>
          </div>
        );
      }}
    </Query>
  );
};
