import gql from "graphql-tag";

const ShowroomFragment = gql`
  fragment ShowroomFragment on Showroom {
    id
    host {
      id
      firstName
      lastName
    }
    hostId
    photos {
      edges {
        node {
          id
          url
          displayOrder
        }
      }
    }
    street
    city
    state
    zipcode
    title
    description
    calendlyEmail
    calendlyUrl
    status
    showroomType
    attributedCta
    showroomClass
    showroomLabel
    attributedLink

    household
    pets
    lifestyle
    spaceType
    configuration
    style
    hostBio
    visitable
    virtualConnectable
  }
`;

export const showroomDetail = gql`
  query showroomDetail($id: ID!) {
    showroom(id: $id) {
      ...ShowroomFragment
    }
  }
  ${ShowroomFragment}
`;

export const updateShowroom = gql`
  mutation updateShowroom($id: ID!, $payload: ShowroomInput!) {
    showroom {
      update(showroomId: $id, payload: $payload) {
        showroom {
          ...ShowroomFragment
        }
      }
    }
  }
  ${ShowroomFragment}
`;

export const createShowroom = gql`
  mutation createShowroom($payload: ShowroomInput!) {
    showroom {
      create(payload: $payload) {
        showroom {
          ...ShowroomFragment
        }
      }
    }
  }
  ${ShowroomFragment}
`;

export const getHosts = gql`
  query getHosts {
    hosts {
      edges {
        node {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;
