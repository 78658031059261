import React from "react";
import styles from "./Spinner.module.scss";

export class Spinner extends React.Component {
  render() {
    return <img src="/img/spinner.gif" className={styles.spinner} alt="Loading" />;
  }
}

export default Spinner;
