import gql from "graphql-tag";

const HostFragment = gql`
  fragment HostFragment on Host {
    id
    email
    firstName
    lastName
    displayName
    phone
    status
    household
    lifestyle
    pets
    bio
    profilePhoto
    phoneSecondary
    emailSecondary
  }
`;

export const getHost = gql`
  query getHost($id: ID!) {
    host(id: $id) {
      ...HostFragment
    }
  }
  ${HostFragment}
`;

export const updateHost = gql`
  mutation updateHost($id: ID!, $payload: HostInput!) {
    host {
      update(hostId: $id, payload: $payload) {
        host {
          ...HostFragment
        }
      }
    }
  }
  ${HostFragment}
`;

export const createHost = gql`
  mutation createHost($payload: HostInput!) {
    host {
      create(payload: $payload) {
        host {
          ...HostFragment
        }
      }
    }
  }
  ${HostFragment}
`;
