import React from "react";
import ShowroomsList from "./List";
import ShowroomDetail from "./DetailPage";
import ShowroomsNew from "./New";
import { Switch, Route } from "react-router-dom";

export default () => (
  <Switch>
    <Route path="/showrooms/new" component={ShowroomsNew} />
    <Route path="/showrooms/:id" component={ShowroomDetail} />
    <Route path="/showrooms" component={ShowroomsList} />
  </Switch>
);
