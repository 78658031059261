import gql from "graphql-tag";

export const reorderPhoto = gql`
  mutation reorderPhoto($id: ID!, $index: Int!) {
    photo {
      reorder(photoId: $id, index: $index) {
        photo {
          id
          displayOrder
          url
        }
      }
    }
  }
`;

export const deletePhoto = gql`
  mutation deletePhoto($id: ID!) {
    photo {
      delete(photoId: $id) {
        photo {
          id
          targetId
        }
      }
    }
  }
`;
