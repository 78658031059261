import gql from "graphql-tag";
import React, { useReducer, useState, useContext, useEffect } from "react";
import { Query, graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import { SearchField } from "../../components/Form/SearchField";
import { ListPageActions } from "../../components/ListPage";
import Spinner from "../../components/Spinner";
import Table from "../../components/Table";
import { connectionToArray } from "../../utils/graphql";
import { PaginationReducer, PAGINATION_PAGE_SIZE } from "../../reducers/pagination";
import styles from "../styles.module.scss";

const APP_QUERY = gql`
  query PaginatedApplicants($searchTerm: String, $first: Int, $after: String, $last: Int, $before: String) {
    applicants(sortKey: "createdAt", reverse: true, query: $searchTerm, first: $first, after: $after, last: $last, before: $before) {
      edges {
        cursor
        node {
          id
          email
          firstName
          lastName
          phone
          status
          location
          zipcode
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

const ApplicantsContext = React.createContext({ cursor: null })

const ApplicantsTable = ({ history, searchTerm }) => {
  const {state, dispatch} = useContext(ApplicantsContext)
  let queryVars = { first: PAGINATION_PAGE_SIZE, searchTerm };

  if (state.cursor) {
    queryVars.after = state.cursor;
  }

  return (
    <div className={styles.listContainer}>
      <Query query={APP_QUERY} variables={queryVars}>
        {({ data, loading }) => {
          if (loading) {
            return <Spinner />;
          }
          let applicants = connectionToArray(data.applicants);
          applicants = applicants.map((app) => {
            app.fullName = `${app.firstName} ${app.lastName}`
            var location = app.location || "";
            const addySplit = location.split(",")

            app.street = location;
            app.city  = "";
            app.state  = "";

            if (addySplit.length === 3 || addySplit.length === 4) {
              app.street = addySplit[0] || ""
              app.city  = addySplit[1] || ""
              app.state  = addySplit[2] || ""
            }
            
            return app
          })
          const { pageInfo } = data.applicants;

          return (
            <React.Fragment>
              <Table
                data={applicants}
                onClick={row => history.push(`/applicants/${row.id}`)}
                headers={[
                  { key: "fullName", label: "Full Name" },
                  { key: "email", label: "Email" },
                  { key: "street", label: "Street Address" },
                  { key: "city", label: "City" },
                  { key: "state", label: "State" },
                  { key: "zipcode", label: "Zipcode" },
                  { key: "status", label: "Status" }
                ]}
              />
              <div className={styles.buttonsContainer}>
                { pageInfo.hasPreviousPage && (
                  <button className={styles.navigateButton} onClick={() => dispatch({ type: "previous", pageInfo: pageInfo })}>
                    Prev
                  </button>
                )}
                { pageInfo.hasNextPage && (
                  <button className={styles.navigateButton} onClick={() => dispatch({ type: "next", pageInfo: pageInfo })}>
                    Next
                  </button>
                )}
              </div>
            </React.Fragment>
          );
        }}
      </Query>
    </div>
  );
};

const ApplicantsPage = ({ match, history }) => {
  const [state, dispatch] = useReducer(PaginationReducer, { cursor: match.params.cursor });
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDisabled, setsearchDisabled] = useState(true); // Disabling search by default so the user isnt trying to search in pages

  useEffect(() => {
    // This is a hack to allow users to hit "back" and go back to the items in the list
    if (state.cursor) {
      history.push(`/applicants_page/${state.cursor}`)
      setsearchDisabled(true);
    }

    if (match.path === "/applicants") {
      setsearchDisabled(false);
    }
  }, [state])

  return (
    <div>
      <ListPageActions>
        <SearchField onSearch={setSearchTerm} disabled={searchDisabled}/>
      </ListPageActions>
      <h3>Applicants</h3>
      <ApplicantsContext.Provider value={{ state, dispatch }}>
        <ApplicantsTable searchTerm={searchTerm} history={history} />
      </ApplicantsContext.Provider>
    </div>
  )
}

export default graphql(APP_QUERY)(withRouter(ApplicantsPage));
