import { Form } from "formik";
import React, { Component } from "react";
import Select from "react-select";
import {
  Button,
  InputWithLabel as Input,
  Section,
  SelectHost,
  TextArea,
  CheckboxWithLabel,
} from "../../components/Form";
import StatusUpdate from "../../components/StatusUpdate";
import PhotoManager from "./PhotoManager";
import styles from "./styles.module.scss";

const showroomTypes = [
  { value: "digitalOnly", label: "Digital Only" },
  { value: "hosted", label: "Hosted " },
  { value: "attributed", label: "Attributed" },
  { value: "basic", label: "Basic" },
  { value: "standard", label: "Standard" },
  { value: "retail", label: "Retail" },
];

const showroomStatus = [
  { value: "inactive", label: "Deactivate" },
  { value: "active", label: "Activate" },
  { value: "comingSoon", label: "Coming Soon" },
  { value: "approved", label: "Approved" },
];

const showroomClass = [
  { value: "residential", label: "Residential" },
  { value: "commercial", label: "Commercial" },
];

type SelectValue = { value: string; label: string };
class ShowroomForm extends Component<any> {
  render() {
    const {
      handleSubmit,
      showroom,
      onCancel,
      setFieldValue,
      values,
      successUpdate,
      errorUpdate,
    } = this.props;

    const colourStyles = {
      control: (styles: any) => ({
        ...styles,
        width: "180px",
        color: "red !important",
        height: "40px",
        fontSize: "15px",
        fontWeight: 500,
        paddingLeft: "10px",
        letterSpacing: "0.5px",
        borderRadius: "3px",
        border: "solid 1px #014e99",
        cursor: "pointer",
        paddingBottom: "1.2em",
        textTransform: "uppercase",
      }),
      option: (styles: any) => {
        return {
          ...styles,
          color: "#18304c !important",
          textAlign: "left",
          height: "32px",
          fontSize: "15px",
          fontWeight: 300,
          letterSpacing: "0.5px",
          cursor: "pointer",
          paddingBottom: "1.2em",
        };
      },
    };

    return (
      <React.Fragment>
        <StatusUpdate
          successUpdate={successUpdate}
          tableName="Showroom"
          errorUpdate={errorUpdate}
        />
        <Form>
          <div className={styles.titleRow}>
            <h3>Showroom Detail</h3>
            <div className={styles.controlContainer}>
              <div className={styles.selectContainer}>
                <Select
                  defaultValue={showroomStatus.find(
                    (status) => status.value === showroom.status
                  )}
                  options={showroomStatus}
                  onChange={(selected) =>
                    setFieldValue("status", (selected as SelectValue).value)
                  }
                  styles={colourStyles}
                />
              </div>
              <Button alternate type="cancel" onClick={onCancel}>
                Cancel
              </Button>
              <Button alternate={false} type="submit" onClick={handleSubmit}>
                Save Changes
              </Button>
            </div>
          </div>
          <Section title="Basic Information">
            <SelectHost
              label="Host"
              selectedValue={values.hostId}
              onSelect={(selected) => setFieldValue("hostId", selected.value)}
            />
            <div />
            <Input label="Showroom Title" name="title" />
            <Input label="Showroom Label" name="showroomLabel" />
            <div className="field">
              <label className={styles.label}>Showroom Type</label>
              <div className="control">
                <Select
                  defaultValue={showroomTypes.find(
                    (types) => types.value === showroom.showroomType
                  )}
                  options={showroomTypes}
                  onChange={(selected) =>
                    setFieldValue(
                      "showroomType",
                      (selected as SelectValue).value
                    )
                  }
                />
              </div>
            </div>
            <div className="field">
              <label className={styles.label}>Showroom Class</label>
              <div className="control">
                <Select
                  defaultValue={showroomClass.find(
                    (c) => c.value === showroom.showroomClass
                  )}
                  options={showroomClass}
                  onChange={(selected) =>
                    setFieldValue(
                      "showroomClass",
                      (selected as SelectValue).value
                    )
                  }
                />
              </div>
            </div>
            {values.showroomType === "attributed" && (
              <React.Fragment>
                <Input label="CTA Label" name="attributedCta" />
                <Input label="CTA Link" name="attributedLink" />
              </React.Fragment>
            )}

            {values.showroomType === "hosted" && (
              <React.Fragment>
                <Input label="Calendly Email" name="calendlyEmail" />
                <Input label="Calendly Booking URL" name="calendlyUrl" />
                <CheckboxWithLabel
                  label="Customer Can Visit"
                  name="visitable"
                />
                <CheckboxWithLabel
                  label="Customer Can Connect Virtually"
                  name="virtualConnectable"
                />
              </React.Fragment>
            )}
          </Section>

          <Section title="Showroom Address">
            <Input label="Street Address" name="street" />
            <Input label="City" name="city" />
            <Input label="State" name="state" />
            <Input label="Zip Code" name="zipcode" />
          </Section>
          {showroom.id && (
            <Section title="Showroom Photos">
              <div className={styles.photoBox}>
                <PhotoManager showroomId={showroom.id} />
              </div>
            </Section>
          )}
          {values.showroomClass === "residential" && (
            <React.Fragment>
              <Section title="Host Description">
                <TextArea name="hostBio" />
              </Section>
              <Section title="Host Attributes">
                <Input label="How Many People In Household?" name="household" />
                <Input
                  label="Lifestyle that best describes the host"
                  name="lifestyle"
                />
                <Input label="Any Pets?" name="pets" />
              </Section>
            </React.Fragment>
          )}
          <Section title="Showroom Description">
            <TextArea name="description" />
          </Section>
          <Section title="Showroom Attributes">
            <Input label="Space Type" name="spaceType" />
            <Input label="Outer Config" name="configuration" />
            <Input label="Design Style" name="style" />
          </Section>
        </Form>
      </React.Fragment>
    );
  }
}

export default ShowroomForm;
