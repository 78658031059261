import React from "react";
import { Route } from "react-router-dom";
import { ApplicantsList, ApplicantDetail } from "./Applicant";
import ShowroomRoutes from "./Showroom";
import HostRoutes from "./Host";
import { ShowingsList, ShowingDetail } from "./Showing";
import { Profile } from "./Profile";
import Logout from "./Auth/Logout";

export default () => (
  <React.Fragment>
    <Route exact path="/applicants" component={ApplicantsList} />
    <Route path="/applicants_page/:cursor" component={ApplicantsList} />
    <Route path="/applicants/:id" component={ApplicantDetail} />
    <Route path="/hosts" component={HostRoutes} />
    <Route path="/showrooms" component={ShowroomRoutes} />
    <Route exact path="/showings" component={ShowingsList} />
    <Route path="/showings/:id" component={ShowingDetail} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/profile" component={Profile} />
  </React.Fragment>
);
