export const login = async values => {
  const authResponse = await fetch(`${process.env.REACT_APP_API_HOST}/sign_in`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(values)
  });

  if (authResponse.ok) {
    setAuthToken(authResponse.headers.get("Authorization"));
    setUsername(values.user.email);
  } else {
    throw(new Error("Login failed"))
  }
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("username");
};

export const setAuthToken = token => {
  localStorage.setItem("token", token);
};

export const getAuthToken = () => {
  return localStorage.getItem("token");
}

export const setUsername = username => {
  localStorage.setItem("username", username);
};

export const getUsername = () => {
  return localStorage.getItem("username");
}
