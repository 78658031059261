import React from "react";
import { graphql, Query } from "react-apollo";
import { Formik } from "formik";
import { Button, TextArea } from "./Form";
import { connectionToArray } from "../utils/graphql";
import { format } from "date-fns";
import { GET_NOTES, CREATE_NOTE } from "../graphql/Note";
import styles from "./Form/styles.module.scss";
import cx from "classnames";

const InnerForm = ({ queryVariables, data, refetch, onSubmit, mutate }) => (
  <Formik
    initialValues={{ content: "" }}
    onSubmit={async values => {
      return mutate({ variables: { ...queryVariables, ...values } }).then(() => refetch());
    }}
  >
    {({ resetForm, submitForm }) => (
      <div className={cx(styles.box, styles.notebox)}>
        <TextArea name="content" />
        <div style={{ display: "inline-block", position: "absolute", right: "2em", top: "210px" }}>
          <Button onClick={() => onSubmit(submitForm, resetForm)}>Save Note</Button>
        </div>
        <ul style={{ marginTop: "4em" }}>
          {connectionToArray(data.notes).map(note => (
            <div key={note.id}>
              <span>
                {format(note.createdAt, "M/D/YYYY @ h:mma")} - {note.author.email}
              </span>
              <p>{note.content}</p>
            </div>
          ))}
        </ul>
      </div>
    )}
  </Formik>
);

class NoteForm extends React.Component {
  onSubmit = async (submitFn, resetFn) => {
    await submitFn();
    resetFn();
  };

  queryVariables = () => {
    return { noteableId: this.props.noteable.id, noteableType: this.props.noteableType };
  };

  render() {
    const queryVariables = this.queryVariables();
    return (
      <Query query={GET_NOTES} variables={queryVariables}>
        {({ data, loading, refetch }) => {
          if (loading) {
            return null;
          }

          return (
            <div>
              <h4>Notes</h4>
              <div className={styles.section}>
                <InnerForm {...{ data, queryVariables, refetch }} onSubmit={this.onSubmit} mutate={this.props.mutate} />
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default graphql(CREATE_NOTE)(NoteForm);
