import React from "react";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

const NavLink = styled(Link)`
  display: block;
  padding: 8px 20px;
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  &.active, &:hover {
    font-weight: 500;
    color: #fff;
    background: #014e99;
  }
`;

const SideContainer = styled.div`
  background: #162f4b;
  color: #fff;
  z-index: 2;
  position: relative;
  min-width: 210px;
  width: 210px;
  img.logo {
    margin: 20px;
    width: 85px;
  }
  a img {
    margin: 0 20px 0 0;
    display: inline-block;
    height: 14px;
  }
`;

export default () => (
  <SideContainer>
    <img src="/img/Logo.png" className="logo" alt="logo"/>
    <NavLink to="/applicants"><img src="/img/icons/icon-applicants.png" alt="applicants"/>Applicants</NavLink>
    <NavLink to="/hosts"><img src="/img/icons/icon-hosts.png" alt="hosts"/>Hosts</NavLink>
    <NavLink to="/showrooms"><img src="/img/icons/icon-spaces.png" alt="showrooms"/>Showrooms</NavLink>
    <NavLink to="/showings"><img src="/img/icons/icon-showings.png" alt="showings"/>Showings</NavLink>
  </SideContainer>
);
