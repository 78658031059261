import React, { useState } from 'react';
import { PostWithProgress } from '../utils/uploader.js';
import { useDropzone } from 'react-dropzone';
import styles from "./AvatarPhoto.module.scss";

const CustomFileUpload = ({ photoUrl, url }) => {
  const [uploading, setUploading] = useState([]);

  const refresh = () => {
    window.location.reload(); 
  };

  const onReject = async (files) => {
    files.forEach(file => {
      console.log(`${file.name} upload failed`);
    });
  };

  const updateProgress = async (name, percent) => {
    setUploading({
      uploading: {
        ...uploading,
        [name]: percent
      }
    });
  };

  const progressAction = async (progress, file) => {
    if (progress.direction === "upload") {
      updateProgress(file.name, progress.percent);
    }
  };

  const onDrop = async (files, callback) => {
    var newState = {};
    for (var i = 0; i < files.length; i++) {
      var file = files[i];
      newState[file.name] = 0;
      setUploading(newState);
      let data = new FormData();
      data.append("file", file);
      try {
        //disabled no-loop-func
        //eslint-disable-next-line 
        await PostWithProgress(url, data, progress => {
          progressAction(progress, file)
        });
        callback();
      } catch {
        console.log(`Error while uploading ${file.name}`);
      }
    }
    refresh();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: onDrop,
    onDropRejected: onReject,
    accept: "image/*",
    maxSize: 20000000
  });

  let photoContent = <span>+</span>;
  let labelText = "Upload a Profile Photo";
  if (photoUrl) {
    photoContent = <img src={photoUrl} alt="avatar"/>;
    labelText = "Change Profile Photo";
  }

  return (
    <div className={styles.avatarPhoto} >
      <div className={styles.avatarCircle}>
        {photoContent}
      </div>
        <div className={styles.label}>
          <div {...getRootProps()}>
            <p>
              {labelText}
              <input type="file" {...getInputProps()} />
            </p>
          </div>
      </div>
      </div>
  );
}

export default CustomFileUpload;