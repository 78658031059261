import { Formik } from "formik";
import { omit } from "lodash";
import React from "react";
import { Mutation, Query } from "react-apollo";
import { withRouter } from "react-router-dom";
import NoteForm from "../../components/NoteForm";
import Spinner from "../../components/Spinner";
import InnerForm from "./Form";
import { getHost, updateHost } from "./Query";

class HostDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      successUpdate: false,
      errorUpdate: false
    };
  }

  render() {
    const match = this.props.match;
    return (
      <Query query={getHost} variables={{ id: match.params.id }}>
        {({ data, loading }) => {
          if (loading) {
            return <Spinner />;
          }
          const { host } = data;
          return (
            <div>
              <h2>Host Detail</h2>
              <Mutation mutation={updateHost}>
                {mutate => (
                  <Formik
                    initialValues={omit(host, ["__typename", "id", "status", "profilePhoto"])}
                    validate={values => {
                      let errors = {};
                      ["firstName", "lastName", "email"].forEach(field => {
                        if (!values[field]) {
                          errors[field] = "Required";
                        }
                      });
                      return errors;
                    }}
                    onSubmit={values => {
                      mutate({ variables: { id: host.id, payload: values } })
                        .then(() => this.setState({ successUpdate: true }))
                        .catch(() => this.setState({ errorUpdate: true }));
                    }}
                    render={({ handleSubmit, errors }) => (
                      <InnerForm
                        onSubmit={handleSubmit}
                        host={host}
                        errors={errors}
                        onCancel={this.handleCancel}
                        successUpdate={this.state.successUpdate}
                        errorUpdate={this.state.errorUpdate}
                      />
                    )}
                  />
                )}
              </Mutation>
              <NoteForm noteable={host} noteableType="Host" />
            </div>
          );
        }}
      </Query>
    );
  }

  handleCancel = () => {
    this.props.history.replace("/hosts");
  };
}

export default withRouter(HostDetailPage);
