import React, { Component } from "react";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Typography from "typography";
import "./App.scss";
import { NotificationProvider } from "./components/Notification/Provider";
import Sidebar from "./components/Sidebar";
import TopBar from "./components/TopBar";
import Routes from "./pages";
import Login from "./pages/Auth/Login";
import { createClient } from "./utils/apollo";

const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.45,
  bodyFontFamily: ["Centra", "sans-serif"],
  headerFontFamily: ["Centra", "sans-serif"],
});

const Container = ({ children }) => (
  <div
    style={{
      width: "100%",
      position: "relative",
      zIndex: "1",
      minHeight: "100vh",
    }}
  >
    <TopBar>Admin</TopBar>
    <div className="content-page">{children}</div>
  </div>
);
class App extends Component {
  constructor(props) {
    super(props);
    typography.injectStyles();
  }

  render() {
    const client = createClient();
    return (
      <Router>
        <Switch>
          <Route
            path="/login"
            render={() => (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  zIndex: "1",
                  backgroundColor: "#162f4b",
                  height: "100vh",
                }}
              >
                <Login />
              </div>
            )}
          />
          <Route
            path="/"
            render={() => (
              <ApolloProvider client={client}>
                <div className="app">
                  <Sidebar />
                  <Container>
                    <NotificationProvider>
                      <Routes />
                    </NotificationProvider>
                  </Container>
                </div>
              </ApolloProvider>
            )}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
