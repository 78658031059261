import { map } from "lodash";

export type PhotoProgress = { name: string; percent: number; preview: any };
export type PhotoManagerState = {
  uploading: PhotoProgress[];
  dragging: boolean;
};

export type PhotoManagerAction =
  | {
      type: "PROGRESS";
      payload: {
        file: File;
        progress: { percent?: number; direction: string };
      };
    }
  | { type: "REFRESH"; payload: any[] }
  | { type: "DROPPED"; payload: File[] }
  | { type: "LOADED_PREVIEW"; payload: { name: string; preview: string } }
  | { type: "START_DRAG" }
  | { type: "STOP_DRAG" };

export const PhotoManagerReducer = (state: PhotoManagerState, action: PhotoManagerAction) => {
  let uploading: any[];
  switch (action.type) {
    case "PROGRESS":
      let { file, progress } = action.payload;
      uploading = map(state.uploading, item => {
        if (item.name !== file.name) {
          return item;
        }
        return { ...item, percent: progress.percent || 0 };
      });
      return { ...state, uploading };
    case "REFRESH":
      return { ...state, photos: action.payload, uploading: [] };
    case "DROPPED":
      return {
        ...state,
        uploading: state.uploading.concat(
          action.payload.map((f: File) => ({
            name: f.name,
            percent: 0,
            preview: ""
          }))
        )
      };
    case "LOADED_PREVIEW":
      uploading = map(state.uploading, item => {
        if (item.name !== action.payload.name) {
          return item;
        }
        return { ...item, preview: action.payload.preview };
      });
      return { ...state, uploading };
    case "START_DRAG":
      return { ...state, dragging: true };
    case "STOP_DRAG":
      return { ...state, dragging: false };
    default:
      return state;
  }
};
