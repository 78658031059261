import React from "react";
import HostsList from "./List";
import HostDetail from "./DetailPage";
import NewHost from "./New";
import { Switch, Route } from "react-router-dom";

export default () => (
  <Switch>
    <Route path="/hosts/new" component={NewHost} />
    <Route path="/hosts/:id" component={HostDetail} />
    <Route path="/hosts" component={HostsList} />
  </Switch>
);
