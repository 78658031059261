import React from "react";
import { debounce } from "lodash";
import styles from "./styles.module.scss";

export class SearchField extends React.Component {
  termChanged = debounce(() => this.props.onSearch(this.refs.search.value), 300);
  render() {
    return (
      <div className={styles.field}>
        <input ref="search" className="input" type="text" placeholder="Search ..." onChange={this.termChanged} disabled={this.props.disabled}/>
      </div>
    );
  }
}
