import React from "react";
import { Section, InputForDisplay as Input, Column } from "../../components/Form";
import { Form } from "formik";
import styles from "./styles.module.scss";

const renderDropQuestion = ({ title, question, answer }) => {
  const ans = answer;
  // rendering answer in the format returned by rails
  // const ans = answer ?
  //   answer
  //   .split(",")[1]
  //   .split("=>")[1]
  //   .split("}")[0]
  //   .replace(/"/g, "") : ''
  return (
    <Section title={title}>
      <div className={styles.surveyQuestion}>
        <div className={styles.answer}>{ans}</div>
      </div>
    </Section>
  );
};

// const formatArray = (value) => {
//   let array;
//   try {
//     array = JSON.parse(value);
//   }
//   catch (e) {
//     return value;
//   }
//   return array.join(', ');
// };

export default ({ applicant }) => {
  return (
    <Form>
      <Section title="Basic Information">
        <div className={styles.formWrapper}>
          <Column>
            <Input label="First Name" value={applicant.firstName} />
            <Input label="Last Name" value={applicant.lastName} />
              <div
                  onClick={() => {
                      window.open(`mailto:${applicant.email}?body=Hi%20${applicant.firstName},`, "_blank");
                  }}
              >
                  <Input label="Email" value={applicant.email} />
              </div>
          </Column>
          <Column>
              <Input label="Phone" value={applicant.phone} />
              <Input label="City / Neighborhood" value={applicant.location} />
              <Input label="Zipcode" value={applicant.zipcode} />
          </Column>
        </div>
      </Section>
      
      {applicant.questionnaire.map((qestionObject, idx) => {
        return renderDropQuestion({
          title: qestionObject.question,
          question: qestionObject.question,
          answer: qestionObject.answer
        })
      })}
    </Form>
  )
};