import { Form } from "formik";
import React from "react";
import DropzoneUploader from "../../components/dropzoneUploader";
import { Button, Column, InputWithLabel as Input, Section } from "../../components/Form";
import StatusUpdate from "../../components/StatusUpdate";

export default ({ onSubmit, host, onCancel, errors, successUpdate, errorUpdate, callback }) => (
  <React.Fragment>
    <StatusUpdate successUpdate={successUpdate} tableName="Host" errorUpdate={errorUpdate} />
    <Form>
      <div style={{ textAlign: "right" }}>
        <Button alternate type="submit" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" onClick={onSubmit}>
          Save
        </Button>
      </div>
      <Section title="Basic Information">
        <Column>
          <Input label="First Name" name="firstName" hasError={errors.firstName} />
          <Input label="Last Name" name="lastName" hasError={errors.lastName} />
          <Input label="Display Name" name="displayName" />
        </Column>
        {host.id && (
          <Column>
            <DropzoneUploader photoUrl={host.profilePhoto} url={`admin/upload_host_avatar/${host.id}`} />
          </Column>
        )}
      </Section>
      <Section title="Contact Information">
        <Column>
          <Input label="Primary Email" name="email" hasError={errors.email} />
          <Input label="Primary Phone" name="phone" />
        </Column>
        <Column>
          <Input label="Secondary Email" name="emailSecondary" />
          <Input label="Secondary Phone" name="phoneSecondary" />
        </Column>
      </Section>
      <Section title="Showrooms" />
    </Form>
  </React.Fragment>
);
