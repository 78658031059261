import { format } from "date-fns";
import gql from "graphql-tag";
import React, { useReducer } from "react";
import { graphql, Query } from "react-apollo";
import { withRouter } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Table from "../../components/Table";
import { PaginationReducer, PAGINATION_PAGE_SIZE } from "../../reducers/pagination";
import { connectionToArray } from "../../utils/graphql";
import styles from "../styles.module.scss";

const APP_QUERY = gql`
  query PaginatedShowings(
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    showings(
      sortKey: "scheduledAt"
      reverse: true
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      edges {
        node {
          id
          status
          scheduledAt
          showroom {
            title
          }
          host {
            fullName
          }
          guest {
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

const mapData = showings =>
  connectionToArray(showings).map(row => ({
    id: row.id,
    GuestName: row.guest.name,
    HostName: row.host.fullName,
    Location: row.showroom.title,
    DateAndTime: row.scheduledAt
      ? format(row.scheduledAt, "M/D/YYYY @ h:mma")
      : "",
    Status: row.status
  }));

const ShowingsPage = ({ history }) => {
  const [state, dispatch] = useReducer(PaginationReducer, { cursor: null });
  let queryVars = { first: PAGINATION_PAGE_SIZE };

  if (state.cursor) {
    queryVars.after = state.cursor;
  }

  return (
    <div className={styles.listContainer}>
      <h3>Showings</h3>

      <Query query={APP_QUERY} variables={queryVars}>
        {({ data, loading }) => {
          if (loading) {
            return <Spinner />;
          }
          const showings = mapData(data.showings);
          const { pageInfo } = data.showings;

          return (
            <React.Fragment>
              <Table
                data={showings}
                onClick={row => history.push(`/showings/${row.id}`)}
                headers={[
                  { key: "GuestName", label: "Guest Name" },
                  { key: "HostName", label: "Host Name" },
                  { key: "Location", label: "Location" },
                  { key: "DateAndTime", label: "Date & Time" },
                  { key: "Status", label: "Status" }
                ]}
              />
              <div className={styles.buttonsContainer}>
                {pageInfo.hasPreviousPage && (
                  <button
                    className={styles.navigateButton}
                    onClick={() =>
                      dispatch({ type: "previous", pageInfo: pageInfo })
                    }
                  >
                    Prev
                  </button>
                )}
                {pageInfo.hasNextPage && (
                  <button
                    className={styles.navigateButton}
                    onClick={() =>
                      dispatch({ type: "next", pageInfo: pageInfo })
                    }
                  >
                    Next
                  </button>
                )}
              </div>
            </React.Fragment>
          );
        }}
      </Query>
    </div>
  );
};

export default graphql(APP_QUERY)(withRouter(ShowingsPage));
