import cx from "classnames";
import { map, reject } from "lodash";
import React, { useReducer } from "react";
import ReactDOM from "react-dom";
import usePortal from "../../utils/portal";

export const NotificationContext = React.createContext({ addNotification: (content: string, type: "failure" | "success") => {} });

type NotificationObject = { type: string; content: string };

export const NotificationReducer = (state: NotificationObject[], action: any) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload];
    case "REMOVE":
      return reject(state, n => n.content === action.payload);
    default:
      return state;
  }
};

const NotificationsList = ({ notifications }: { notifications: NotificationObject[] }) => {
  const target = usePortal("notifications");
  if (!target) {
    return null;
  }
  const notificationElements = (
    <React.Fragment>
      {map(notifications, (n: NotificationObject) => {
        const notifClasses = cx({ notification: true, "is-danger": n.type === "failure" });
        return (
          <div key={Math.random()} className={notifClasses}>
            {n.content}
          </div>
        );
      })}
    </React.Fragment>
  );
  return ReactDOM.createPortal(notificationElements, target);
};

export const NotificationProvider = ({ children }) => {
  let [notifications, dispatch] = useReducer(NotificationReducer, []);

  const removeNotification = (content: string) => {
    dispatch({ type: "REMOVE", payload: content });
  };

  const addNotification = (content: string, type: "failure" | "success") => {
    dispatch({ type: "ADD", payload: { content, type } });

    setTimeout(() => {
      removeNotification(content);
    }, 1000);
  };

  return (
    <React.Fragment>
      <NotificationContext.Provider value={{ addNotification }}>{children}</NotificationContext.Provider>
      <NotificationsList notifications={notifications} />
    </React.Fragment>
  );
};
