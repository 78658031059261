import { Formik } from "formik";
import gql from "graphql-tag";
import { omit } from "lodash";
import React from "react";
import { Mutation, Query } from "react-apollo";
import Spinner from "../../components/Spinner";
import InnerForm from "./Form";
import styles from "./styles.module.scss";

const CURRENT_USER_QUERY = gql`
  query getCurrentUser {
    me {
      id
      email
      firstName
      lastName
      profilePhoto
    }
  }
`;

const UPDATE_USER_MUTATION = gql`
  mutation updateMeMutation($payload: AdminUserInput!) {
    me {
      update(payload: $payload) {
        user {
          id
          firstName
          lastName
          profilePhoto
        }
      }
    }
  }
`;

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      successUpdate: false,
      errorUpdate: false
    };
  }

  render() {
    return (
      <Query query={CURRENT_USER_QUERY}>
        {({ data, loading }) => {
          if (loading) {
            return <Spinner />;
          }
          return (
            <div className={styles.profile}>
              <Mutation mutation={UPDATE_USER_MUTATION}>
                {mutate => (
                  <Formik
                    initialValues={data.me}
                    onSubmit={async values => {
                      await mutate({ variables: { payload: omit(values, ["__typename", "id", "profilePhoto"]) } })
                        .then(() => this.setState({ successUpdate: true }))
                        .catch(() => this.setState({ errorUpdate: true }));
                    }}
                    render={props => <InnerForm {...props} successUpdate={this.state.successUpdate} errorUpdate={this.state.errorUpdate} />}
                  />
                )}
              </Mutation>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default Profile;
