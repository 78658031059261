import React from "react";
import { Form } from "formik";
import { Section, InputWithLabel as Input, Button, Column } from "../../components/Form";
import styles from "./styles.module.scss";
import DropzoneUploader from "../../components/dropzoneUploader";
import StatusUpdate from "../../components/StatusUpdate";

export default ({ onSubmit, values, successUpdate, errorUpdate }) => {
  return (
    <React.Fragment>
      <StatusUpdate
        successUpdate={successUpdate}
        tableName="Profile"
        errorUpdate={errorUpdate}
      />
      <Form>
        <div className={styles.profileForm}>
          <h3>Admin Profile</h3>
          <div>
            <Button alternate type="submit" onClick={onSubmit}>
              Cancel
            </Button>
            <Button type="submit" onClick={onSubmit}>
              Save Changes
            </Button>
          </div>
        </div>
        <Section title="Basic Information">
          <Column>
            <Input label="First Name" name="firstName" />
            <Input label="Last Name" name="lastName" />
          </Column>
          <Column>
            <DropzoneUploader photoUrl={values.profilePhoto} url={`admin/upload_user_avatar`} />
          </Column>
        </Section>
        <Section title="Password">
          <Column>
            <Input type="password" label="Type Old Password" name="oldPassword" />
            <Input type="password" label="Type New Password" name="newPassword" />
          </Column>
          <Column>
            <Input type="password" label="Confirm New Password" name="newPasswordConfirmation" />
          </Column>
        </Section>
      </Form>
    </React.Fragment>
  );
};
