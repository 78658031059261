import { ApolloQueryResult } from "apollo-client";
import { sortBy } from "lodash";
import React from "react";
import { useMutation } from "react-apollo";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { deletePhoto } from "../../../graphql/Photo";
import { PhotoProgress } from "./Reducer";
import styles from "./styles.module.scss";

const ProgressThumb: React.FunctionComponent<{ progress: PhotoProgress }> = ({
  progress,
}: {
  progress: PhotoProgress;
}) => {
  return (
    <div
      className={styles.photoFrame}
      style={{
        backgroundImage: `url(${progress.preview})`,
        backgroundSize: "cover",
        width: "240px",
      }}
    >
      <div
        className={styles.progressBar}
        style={{ width: `${Math.floor(progress.percent)}%` }}
      />
    </div>
  );
};

type RefetchFn = (
  variables?: { id: string } | undefined
) => Promise<ApolloQueryResult<any>>;
type PhotoListProps = {
  items: any[];
  loading: boolean;
  refetch: RefetchFn;
  uploading: PhotoProgress[];
};
export const PhotoList = SortableContainer(
  ({ items, loading, refetch, uploading }: PhotoListProps) => {
    return (
      <div className={styles.photos}>
        {!loading &&
          sortBy(items, "displayOrder").map((value, index) => (
            <Photo
              value={value}
              refetch={refetch}
              key={"item-" + value.id}
              index={index}
            />
          ))}
        {uploading.map((progress) => (
          <ProgressThumb key={progress.name} progress={progress} />
        ))}
      </div>
    );
  }
);

const Handle = SortableHandle(() => <div className={styles.handle} />);
const Photo = SortableElement(
  ({ value, refetch }: { value: any; refetch: RefetchFn }) => {
    const [mutate] = useMutation(deletePhoto);
    return (
      <div key={value.id} className={styles.photoFrame}>
        <Handle />
        <div
          className={styles.photo}
          style={{ backgroundImage: "url(" + value.url + ")" }}
        />
        <div
          className={styles.delete}
          onClick={async () => {
            await mutate({
              variables: { id: value.id },
            });
            refetch();
          }}
        />
      </div>
    );
  }
);
