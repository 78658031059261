import React, { useEffect, useState, useRef } from "react";
import * as turf from "turf";
import styles from "./Mapbox.module.scss";

const MAP_BOX_KEY = process.env.REACT_APP_MAP_BOX_KEY;
const MAP_BOX_BASE_URL = "https://api.mapbox.com"
const MAP_BOX_PATH = "geocoding/v5/mapbox.places"


export const getCoordsFromAddress = async (address) => {
  const loc = await getLocation(address)
  return loc;
}

export const getLocation = async (rawAddress) => {
  const url = createMapboxUrl(rawAddress);
  var res = await fetch(url);
  res = await res.json();
  const address = res.features[0].place_name;
  const coords = res.features[0].center;
  return {
    address,
    coords
  }
}

export const getLocationsFromAddressList  = async (addrresses) => {
  const out = [];
  for(let i =0; i < addrresses.length; i++) {
    const address = addrresses[i];
    const loc = await getLocation(address);
    out.push(loc);
  }
  return out;
}

export const createMapboxUrl = (address) => {
  const url =`${MAP_BOX_BASE_URL}/${MAP_BOX_PATH}/${encodeURI(address)}.json?access_token=${MAP_BOX_KEY}&autocomplete=true&limit=1`;
  return url
}

const newMultiPointMap = (targetNode, targetLocations) => {
  let center;
  if (targetLocations.length > 1) {
    const turfFeatures = turf.featureCollection(targetLocations.map((loc) => {
      return turf.point(loc.coords);
    }));
    center = turf.center(turfFeatures);
  } else {
    center = targetLocations[0].coords
  }
  window.mapboxgl.accessToken = MAP_BOX_KEY;
  const map = new window.mapboxgl.Map({
      container: targetNode,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: center,
      zoom: 12
    })

    const markers = targetLocations.map((loc) => {
      return new window.mapboxgl.Marker()
          .setLngLat(loc.coords)
          .addTo(map);
    })
  return { markers, map };
}

const useMap = () => {
  const [map, setMap] = useState(null);
  useEffect(() => {
    if (window.mapboxgl === undefined) {
      const script = document.createElement("script")
      script.src = "https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.0/mapbox-gl.js";
      script.onload = () => {
        setMap(window.mapboxgl);
      }
      document.body.appendChild(script);

  
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = "https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.0/mapbox-gl.css";
      document.body.appendChild(link);
    
      return () => {
        document.body.removeChild(script)
        document.body.removeChild(link)
        window.mapboxgl = undefined;
      }
    }
  }, [])

  return map;
}

export const PointsListMap = ({ targetAddressList }) => {
  const mapScript = useMap();
  const mapRef = useRef(null);
  const [ mapObj, setMapObj ] = useState({map: null, markers: null});

  useEffect(() => {
    if (mapScript !== null && targetAddressList !== undefined && mapObj.map === null) {
      getLocationsFromAddressList(targetAddressList)
        .then((location) => {
          const myMap = newMultiPointMap(mapRef.current, location)
          setMapObj(myMap)
        })
        .catch((err) => {
          console.log("ERROR: ", err)
        })
    }
  }, [mapScript, targetAddressList])

  if (!targetAddressList) {
    return null
  }

  return (
    <div ref={mapRef} className={styles.map}/>
  )
}


export const PointMap = ({ targetAddress }) => {
  return <PointsListMap targetAddressList={[targetAddress]}/>
}