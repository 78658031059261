import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { getAuthToken, logout } from "./auth";

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: `Bearer ${getAuthToken()}`
    }
  });
  return forward(operation);
});
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_HOST}/admin/graphql`
});

const handleErrors = onError(({ networkError }) => {
  if (!networkError) {
    return null;
  }
  if (networkError["statusCode"] === 401) {
    logout();
    window.location.replace("/login");
  }
  if (networkError.name === "TypeError") {
    window.location.replace("/offline");
  }
  return true;
});

// const tokenRefresher = new ApolloLink((operation, forward) => {
//   return forward(operation).map(response => {
//     const {
//       response: { headers }
//     } = operation.getContext();
//     if (headers) {
//       const token = headers.get("Authorization");
//       if (token) {
//         setAuthToken(token);
//       }
//     }

//     return response;
//   });
// });

export const createLink = () => middlewareLink.concat(handleErrors.concat(httpLink));

const cache = new InMemoryCache();

export default new ApolloClient({
  connectToDevTools: true,
  link: createLink(),
  cache: cache
});

export const createClient = () =>
  new ApolloClient({
    connectToDevTools: true,
    link: createLink(),
    cache: cache
  });
