import React from "react";
import styles from "./Table.module.scss";
import PropTypes from "prop-types";

const TableRow = ({headers, row, onClick}) => {
  return (
    <tr onClick={onClick}>
      {headers.map(header => (
        <td key={header.key}>{row[header.key]}</td>
      ))}
    </tr>
  );
};

export default class Table extends React.Component {
  render() {
    return (
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
          <tr>
            {this.props.headers.map(header => (
              <th key={header.key}>{header.label}</th>
            ))}
          </tr>
          </thead>
          <tbody>
          {this.props.data.map((row, i) => {
            return <TableRow key={i} headers={this.props.headers} row={row} onClick={() => this.props.onClick(row)}/>;
          })}
          </tbody>
        </table>
      </div>
    );
  }
}

Table.defaultProps = {
  onClick: () => {},
  data: []
};

Table.propTypes = {
  onClick: PropTypes.func,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.array
};