import React from "react";
import { Formik } from "formik";
import { Mutation } from "react-apollo";
import { withRouter } from "react-router-dom";
import InnerForm from "./Form";
import { createShowroom } from "../../graphql/Showroom";

class ShowroomNewPage extends React.Component {
  render() {
    return (
      <div>
        <Mutation mutation={createShowroom}>
          {mutate => (
            <Formik
              initialValues={{}}
              onSubmit={async values => {
                values.status = 'inactive';
                let results = await mutate({ variables: { payload: values } });
                console.log(results);
                alert("Data saved.");
              }}
              render={props => <InnerForm {...props} showroom={{ host: { id: 0 } }} onCancel={this.handleCancel} />}
            />
          )}
        </Mutation>
      </div>
    );
  }

  handleCancel = () => {
    this.props.history.replace("/showrooms");
  };
}

export default withRouter(ShowroomNewPage);
