import { format } from "date-fns";
import { Form } from "formik";
import gql from "graphql-tag";
import React from "react";
import { Mutation } from "react-apollo";
import { Button, Column, InputForDisplay as Input, Section } from "../../components/Form";

const completeMutation = gql`
  mutation completeShowing($id: ID!) {
    showing {
      complete(showingId: $id) {
        showing {
          id
          status
        }
      }
    }
  }
`;

export default ({ showing }) => {
  const uuid = showing.calendlyActionUuid;
  const { host, guest } = showing;
  return (
    <Form>
      <div style={{ textAlign: "right" }}>
        {showing.status !== "completed" && (
          <Mutation mutation={completeMutation}>
            {mutate => (
              <Button alternate type="submit" onClick={() => mutate({ variables: { id: showing.id } })}>
                Mark as Complete
              </Button>
            )}
          </Mutation>
        )}

        <Button
          type="submit"
          onClick={() => {
            window.open(`https://calendly.com/cancellations/${uuid}`, "_blank");
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={() => {
            window.open(`https://calendly.com/reschedulings/${uuid}`, "_blank");
          }}
        >
          Reschedule
        </Button>
      </div>
      <Section title="Event Information">
        <Column>
          <Input label="Date" value={format(showing.scheduledAt, "MM/DD/YYYY")} />
        </Column>
        <Column>
          <Input label="Time" value={format(showing.scheduledAt, "hh:mm a")} />
        </Column>
      </Section>
      <Section title="Host Information">
        <Column>
          <Input label="First Name" value={host.firstName} />
          <Input label="Last Name" value={host.lastName} />
        </Column>
        <Column>
          <Input label="Email" value={host.email} />
          <Input label="Phone" value={host.phone} />
        </Column>
      </Section>
      <Section title="Guest Information">
        <Column>
          <Input label="Name Name" value={guest.name} />
        </Column>
        <Column>
          <Input label="Email" value={guest.email} />
          <Input label="Phone" value={guest.phone} />
        </Column>
      </Section>
    </Form>
  );
};
