import React from "react";
import styles from "./TopBar.module.scss";
import { Link } from "react-router-dom";
import { getUsername } from "../utils/auth";

export default class TopBar extends React.Component {
  render() {
    return (
      <div className={styles.bar}>
        <div className={styles.dropdown}>
          <span>{getUsername()}</span>
          <span className={styles.avatar}></span>
          <div className={styles.dropdownContent}>
            <ul className={styles.dropdownMenu}>
              <li>
                <Link to="/profile">Profile</Link>
              </li>
              <li>
                <Link to="/logout">Sign Out</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
