export const PAGINATION_PAGE_SIZE = 50;

interface PaginationState {
  cursor: string;
}

const getPreviousCursor = (startCursor, pageSize) => {
  return btoa((parseInt(atob(startCursor)) - (pageSize + 1)).toString())
}

export const PaginationReducer = (
  state: PaginationState,
  action: {
    type: "next" | "previous" | "reset";
    pageInfo: {
      startCursor: string;
      endCursor: string
    }
  }
): PaginationState => {
  const {
    type,
    pageInfo: { startCursor, endCursor }
  } = action;
  switch (type) {
    case "next":
      return Object.assign({}, state, { cursor: endCursor });
    case "previous":
      return Object.assign({}, state, { cursor: getPreviousCursor(startCursor, PAGINATION_PAGE_SIZE) });
    case "reset":
        return Object.assign({}, state, { cursor: undefined });
    default:
      throw new Error();
  }
};
