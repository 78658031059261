import { Formik } from "formik";
import React from "react";
import { Mutation } from "react-apollo";
import { withRouter } from "react-router-dom";
import InnerForm from "./Form";
import { createHost } from "./Query";

class NewHostPage extends React.Component {
  render() {
    return (
      <div>
        <h3>New Host</h3>
        <Mutation mutation={createHost}>
          {mutate => (
            <Formik
              initialValues={{}}
              validate={values => {
                let errors = {};
                ["firstName", "lastName", "email"].forEach(field => {
                  if (!values[field]) {
                    errors[field] = "Required";
                  }
                });
                return errors;
              }}
              onSubmit={values => {
                mutate({ variables: { payload: values } }).then(({ data }) => {
                  this.props.history.push(`/hosts/${data.host.create.host.id}`);
                });
              }}
              render={({ handleSubmit, errors }) => (
                <InnerForm onSubmit={handleSubmit} host={{}} errors={errors} onCancel={this.handleCancel} />
              )}
            />
          )}
        </Mutation>
      </div>
    );
  }

  handleCancel = () => {
    this.props.history.replace("/hosts");
  };
}

export default withRouter(NewHostPage);
