import React from "react";
import { withRouter } from "react-router-dom";
import { login } from "../../utils/auth";
import { InputWithLabel as Input, Button } from "../../components/Form";
import { Form, Formik } from "formik";
import Spinner from "../../components/Spinner";

class Login extends React.Component {

  state = {
    errorText: null,
    loggingIn: false
  };

  login = async (values) => {

    // validation: don't submit if the values are blank
    if(!values || !values.user || !values.user.email || !values.user.password) {
      this.setState({
        errorText: 'Please enter a valid username and password.'
      });
      return;
    }

    // clear the error
    this.setState({
      errorText: null
    });

    try {
      this.setState({ loggingIn: true });
      await login(values);
      this.setState({ loggingIn: false });
      this.props.history.replace("/applicants");
    } catch (err) {
      this.setState({
        errorText: 'Login has failed.  Please try again.',
        loggingIn: false
      });
    }
  };

  render() {
    return (
      <div className="login-form">
        <img src="/img/Logo.png" className="logo" alt="logo"/>
        <Formik
          initialValues={{ user: { email: "", password: "" } }}
          onSubmit={values => {
            this.login(values);
          }}
          render={() => (
            <Form>
              <Input name="user.email" label="Email" autoComplete="email" />
              <Input name="user.password" label="Password" type="password" autoComplete="current-password" />
              {this.state.errorText && <div className="error">{this.state.errorText}</div>}
              {this.state.loggingIn ? <Spinner/> : <Button type="submit">Login</Button>}
            </Form>
          )}
        />
      </div>
    );
  }
}

export default withRouter(Login);
