import gql from "graphql-tag";
import React, { useReducer, useState } from "react";
import { graphql, Query } from "react-apollo";
import { Link, withRouter } from "react-router-dom";
import { SearchField } from "../../components/Form/SearchField";
import formStyles from "../../components/Form/styles.module.scss";
import { ListPageActions } from "../../components/ListPage";
import Spinner from "../../components/Spinner";
import Table from "../../components/Table";
import { PaginationReducer, PAGINATION_PAGE_SIZE } from "../../reducers/pagination";
import { connectionToArray } from "../../utils/graphql";
import styles from "../styles.module.scss";

const APP_QUERY = gql`
  query PaginatedShowrooms($searchTerm: String, $first: Int, $after: String, $last: Int, $before: String) {
    showrooms(query: $searchTerm, first: $first, after: $after, last: $last, before: $before, reverse: true) {
      edges {
        cursor
        node {
          id
          host {
            id
            firstName
            lastName
          }
          street
          city
          state
          zipcode
          setConfig
          title
          status
          showroomLabel
          showroomClass
          showroomType
          attributedCta
          attributedLink
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

const mapShowroomType = showroomType => {
  if (showroomType === "digitalOnly") return "Digital Showroom";
  if (showroomType === "hosted") return "Hosted Showroom";
  if (showroomType ===  "basic") return "Basic";
  if (showroomType === "standard") return  "Standard";

  return "Attributed Showroom";
};

const mapData = showrooms =>
  connectionToArray(showrooms).map(row => ({
    id: row.id,
    HostName: row.host.firstName,
    HostLastName: row.host.lastName,
    Address: row.street,
    Configuration: row.setConfig,
    ShowroomVariant: mapShowroomType(row.showroomType),
    Status: row.status
  }));

const ShowroomsPage = ({ history }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [state, dispatch] = useReducer(PaginationReducer, { cursor: null });
  let queryVars = { first: PAGINATION_PAGE_SIZE, searchTerm };

  if (state.cursor) {
    queryVars.after = state.cursor;
  }

  return (
    <div className={styles.listContainer}>
      <ListPageActions>
        <SearchField onSearch={setSearchTerm} />
        <Link to="/showrooms/new" className={formStyles.button}>
          Add New Showroom
        </Link>
      </ListPageActions>
      <h3>Showrooms</h3>

      <Query query={APP_QUERY} variables={queryVars}>
        {({ data, loading }) => {
          if (loading) {
            return <Spinner />;
          }

          const showrooms = mapData(data.showrooms);
          const { pageInfo } = data.showrooms;

          return (
            <React.Fragment>
              <Table
                data={showrooms}
                onClick={row => history.push(`/showrooms/${row.id}`)}
                headers={[
                  { key: "HostName", label: "Host Name" },
                  { key: "HostLastName", label: "Host Last Name" },
                  { key: "Address", label: "Address" },
                  { key: "Configuration", label: "Configuration" },
                  { key: "ShowroomVariant", label: "Showroom Variant" },
                  { key: "Status", label: "Status" }
                ]}
              />
              <div className={styles.buttonsContainer}>
                {pageInfo.hasPreviousPage && (
                  <button className={styles.navigateButton} onClick={() => dispatch({ type: "previous", pageInfo: pageInfo })}>
                    Prev
                  </button>
                )}
                {pageInfo.hasNextPage && (
                  <button className={styles.navigateButton} onClick={() => dispatch({ type: "next", pageInfo: pageInfo })}>
                    Next
                  </button>
                )}
              </div>
            </React.Fragment>
          );
        }}
      </Query>
    </div>
  );
};

export default graphql(APP_QUERY)(withRouter(ShowroomsPage));
