import cx from "classnames";
import {Field} from "formik";
import React from "react";
import styles from "./styles.module.scss";

export const InputWithLabel = ({
  label,
  name,
  type,
  placeholder,
  hasError
}: {
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
  hasError?: boolean;
}) => {
  const classes = cx({field: true, hasError: hasError});

  return (
    <div className={classes}>
      <label className={styles.label}>{label}</label>
      <div className="control">
        <Field className="input" name={name} type={type || "text"} placeholder={placeholder || ""} />
      </div>
    </div>
  );
};

export const CheckboxWithLabel = ({label, name, placeholder = "", hasError = false}) => {
  const classes = cx({field: true, hasError: hasError});

  return (
    <div className={classes}>
      <label className={styles.label}>{label}</label>
      <div className="control">
        <Field name={name}>
          {({field, form}) => (
            <input
              type="checkbox"
              checked={field.value}
              onChange={e => {
                form.setFieldValue(name, e.target.checked);
              }}
            />
          )}
        </Field>
      </div>
    </div>
  );
};

export const SelectWithLabel = ({label, name, placeholder, hasError, options}) => {
  const classes = cx({field: true, hasError: hasError});
  return (
    <div className={classes}>
      <label className={styles.label}>{label}</label>
      <div className="control">
        <Field className={styles.select} component="select" name={name} placeholder={placeholder || ""}>
          {options}
        </Field>
      </div>
    </div>
  );
};

export const TextArea = ({name}) => <Field component="textarea" name={name} />;

export const Button = ({type, children, onClick, alternate}) => {
  const classes = cx({[styles.button]: true, [styles.alternate]: alternate});
  return (
    <button type={type} onClick={onClick} className={classes}>
      {children}
    </button>
  );
};

export const InputForDisplay = ({label, type, value}) => {
  return (
    <div className="field">
      <label className={styles.label}>{label}</label>
      <div className="control">
        <input value={value ? value : ""} type={type} disabled className="input" />
      </div>
    </div>
  );
};
