import React from "react";
import {withRouter} from "react-router-dom";
import { logout } from "../../utils/auth";

class Logout extends React.Component {

  componentDidMount() {
    logout();
    this.props.history.replace("/login");
  }

  render() {
    return (
      <div>
        Logging out...
      </div>
    );
  }
}

export default withRouter(Logout);
