import cx from "classnames";
import React, { useContext, useReducer } from "react";
import { useMutation, useQuery } from "react-apollo";
import { FileRejection, useDropzone } from "react-dropzone";
import agent from "superagent";
import { NotificationContext } from "../../../components/Notification/Provider";
import { reorderPhoto } from "../../../graphql/Photo";
import { showroomDetail } from "../../../graphql/Showroom";
import { connectionToArray } from "../../../utils/graphql";
import { PhotoList } from "./PhotoList";
import { PhotoManagerReducer } from "./Reducer";
import styles from "./styles.module.scss";

const getBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const PhotoManager = ({ showroomId }: { showroomId: string }) => {
  const [state, dispatch] = useReducer(PhotoManagerReducer, {
    uploading: [],
    dragging: false,
  });
  const [reorder] = useMutation(reorderPhoto);
  const { data, loading, refetch } = useQuery(showroomDetail, {
    variables: { id: showroomId },
  });

  const { showroom } = data;
  const photos = connectionToArray(showroom.photos);

  const onSort = async ({ oldIndex, newIndex }) => {
    await reorder({
      variables: { id: photos[oldIndex].id, index: newIndex },
    });
    refetch();
  };

  const refresh = async () => {
    let { data } = await refetch();
    dispatch({
      type: "REFRESH",
      payload: connectionToArray(data.showroom.photos),
    });
  };

  const generatePreviews = async (
    files: File[],
    dispatch: (value: any) => void
  ): Promise<void> => {
    files.forEach(async (f: File) => {
      let image = await getBase64(f);
      dispatch({
        type: "LOADED_PREVIEW",
        payload: { name: f.name, preview: image },
      });
    });
  };

  const progressAction = (
    progress: { percent?: number | undefined; direction: string },
    file: File
  ): void => {
    if (progress.direction === "upload") {
      dispatch({ type: "PROGRESS", payload: { progress, file } });
    }
  };

  const { addNotification } = useContext(NotificationContext);

  const onDrop = async (files: File[]) => {
    dispatch({ type: "DROPPED", payload: files });
    generatePreviews(files, dispatch);
    for (var i = 0; i < files.length; i++) {
      var file = files[i];
      let data = new FormData();
      data.append("file", file);
      data.append("attachable_guid", `gid://outer/Showroom/${showroom.id}`);
      let url = [process.env.REACT_APP_API_HOST, "admin/images"].join("/");
      try {
        await agent
          .post(url)
          .on("progress", (progress) => progressAction(progress, file))
          .send(data);
      } catch {
        addNotification(`Error while uploading ${file.name}`, "failure");
      }
    }
    refresh();
  };

  const onReject = async (rejections: FileRejection[]) => {
    rejections.forEach((rejection) => {
      addNotification(
        `${rejection.file.name} is too large to be uploaded`,
        "failure"
      );
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: onDrop,
    onDragEnter: () => dispatch({ type: "START_DRAG" }),
    onDragLeave: () => dispatch({ type: "STOP_DRAG" }),
    onDropRejected: onReject,
    accept: "image/*",
    maxSize: 20000000,
  });

  let rootClasses = cx({
    [styles.dragging]: state.dragging,
    [styles.dragArea]: true,
  });
  return (
    <div>
      <div {...getRootProps({ className: rootClasses })}>
        <p>
          <strong>Drop Files Here</strong>
        </p>
        <p>or</p>
        <p>
          <button
            className="button inverted"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Select Files
          </button>
          <input type="file" {...getInputProps()} />
        </p>
      </div>
      <PhotoList
        items={photos}
        uploading={state.uploading}
        refetch={refetch}
        axis="xy"
        onSortEnd={onSort}
        distance={10}
        useDragHandle={true}
        loading={loading}
      />
    </div>
  );
};

export default PhotoManager;
