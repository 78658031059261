import agent from 'superagent';

import { getAuthToken } from "./auth";

const ApiBaseUrl = process.env.REACT_APP_API_HOST + '/';

const GenerateUrl = url => {
  return ApiBaseUrl + url;
};

const decorateRequest = request => {
  return request
    .set('Authorization', `Bearer ${getAuthToken()}`)
    .ok(res => res.status < 500)
    .on('error', errorHandler)
};

const errorHandler = err => {
  console.log(err)
};

export const PostWithProgress = async (
  url,
  data,
  progressHandler
) => {
  let response = await decorateRequest(
    agent
      .post(GenerateUrl(url))
      .on('progress', progressHandler)
      .send(data)
  );
  return response.body;
};