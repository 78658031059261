import React from "react";


export default ({ successUpdate, tableName, errorUpdate }) => (
  <React.Fragment>
    { successUpdate &&
        <div className="responseUpdate successUpdate">
          {tableName} details have been successfully updated.
        </div>
      }
      { errorUpdate &&
        <div className="responseUpdate errorUpdate">
          Error while updating {tableName} data.
        </div>
      }
  </React.Fragment>
);
  