import { Formik } from "formik";
import { forEach, omit } from "lodash";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { withRouter } from "react-router-dom";
import NoteForm from "../../components/NoteForm";
import Spinner from "../../components/Spinner";
import { showroomDetail, updateShowroom } from "../../graphql/Showroom";
import InnerForm from "./Form";

const ShowroomDetailPage = ({ match, history }) => {
  let [state, setState] = useState({ success: false, error: false });

  const handleCancel = () => {
    history.replace("/showrooms");
  };
  const { data, loading } = useQuery(showroomDetail, {
    variables: { id: match.params.id },
  });
  const [mutate] = useMutation(updateShowroom);

  if (loading) {
    return <Spinner />;
  }

  const { showroom } = data;

  let initialValues = omit(showroom, ["__typename", "id", "photos", "host"]);
  forEach(initialValues, (v, k) => {
    if (v === null) {
      initialValues[k] = "";
    }
  });

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          mutate({ variables: { id: showroom.id, payload: values } })
            .then(() => setState({ success: true }))
            .catch((err) => setState({ error: true }));
        }}
        render={(props) => (
          <InnerForm
            {...props}
            showroom={showroom}
            onCancel={handleCancel}
            successUpdate={state.success}
            errorUpdate={state.error}
          />
        )}
      />
      <NoteForm noteable={showroom} noteableType="Showroom" />
    </div>
  );
};

export default withRouter(ShowroomDetailPage);
