import gql from "graphql-tag";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import Lightbox from "react-images";
import { withRouter } from "react-router-dom";
import { Button } from "../../components/Form";
import NoteForm from "../../components/NoteForm";
import { connectionToArray } from "../../utils/graphql";
import InnerForm from "./Form";
import styles from "./styles.module.scss";
import * as Mapbox from "../../components/Mapbox";

const APP_QUERY = gql`
  query getApplicant($id: ID!) {
    applicant(id: $id) {
      id
      email
      firstName
      lastName
      phone
      status
      consideration
      backyardUsage
      availability
      status
      photos {
        edges {
          node {
            id
            url
            displayOrder
          }
        }
      }
      questionnaire {
        question
        answer
      }
      location
      zipcode
    }
  }
`;

const startReviewMutation = gql`
  mutation reviewApplicant($id: ID!) {
    applicant {
      startReview(applicantId: $id) {
        applicant {
          id
          status
        }
      }
    }
  }
`;

const HostDetailPage = ({ match }) => {
  const { data, loading } = useQuery(APP_QUERY, {
    variables: { id: match.params.id },
  });
  const [startReview] = useMutation(startReviewMutation);
  let [lightboxState, setLightboxState] = useState({ open: false, index: 0 });

  const handleCopy = (text) => {
    const copyBox = document.createElement("input");
    copyBox.style = "display:none;";
    copyBox.value = "hello";
    document.body.appendChild(copyBox);
    copyBox.select();

    document.execCommand("copy");
    // document.body.removeChild(copyBox);
  };

  if (loading) {
    return <div>Loading</div>;
  }

  let photos = connectionToArray(data.applicant.photos);

  return (
    <div className={styles.applicant}>
      <div className={styles.titleRow}>
        <h3>Host Applicant ({data.applicant.status})</h3>
        <div>
          {data.applicant.status === "new" && (
            <Button
              alternate
              onClick={() => {
                startReview({ variables: { id: data.applicant.id } });
              }}
            >
              START REVIEW
            </Button>
          )}

          {/* Removing controls since the controls are not currently used
          {data.applicant.status === "in_review" && (
            <Button
              alternate
              onClick={() => {
                deferApplicant({ variables: { id: data.applicant.id } });
              }}
            >
              DEFER
            </Button>
          )}

          {(data.applicant.status === "in_review" || data.applicant.status === "deferred") && (
            <Button
              onClick={() => {
                approveApplicant({ variables: { id: data.applicant.id } });
              }}
            >
              APPROVE
            </Button>
          )} */}
        </div>
      </div>

      <InnerForm applicant={data.applicant} />
      <div className={styles.titleRow}>
        <h4>
          Location:{" "}
          <a
            onClick={() => {
              handleCopy(data.applicant.location);
            }}
          >
            {data.applicant.location}
          </a>
        </h4>
      </div>
      <div className={styles.mapContainer}>
        <Mapbox.PointMap targetAddress={data.applicant.location} />
      </div>

      <div className={styles.titleRow}>
        <h4>Showroom Photos</h4>
      </div>
      <div className={styles.photos}>
        {photos.map((photo, i) => {
          return (
            <div
              key={i}
              className={styles.photoBorder}
              onClick={() => {
                setLightboxState({ open: true, index: i });
              }}
            >
              <img src={photo.url} alt="Applicant Space" />
            </div>
          );
        })}
      </div>
      <Lightbox
        images={photos.map((photo) => ({ src: photo.url }))}
        isOpen={lightboxState.open}
        currentImage={lightboxState.index}
        backdropClosesModal={true}
        onClose={() => {
          setLightboxState({ open: false });
        }}
        onClickNext={() => {
          setLightboxState({ open: true, index: lightboxState.index + 1 });
        }}
        onClickPrev={() => {
          setLightboxState({ open: true, index: lightboxState.index - 1 });
        }}
      />
      <NoteForm noteable={data.applicant} noteableType="Applicant" />
    </div>
  );
};

export default withRouter(HostDetailPage);
