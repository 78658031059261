import gql from "graphql-tag";

export const GET_NOTES = gql`
  query getNotes($noteableId: ID!, $noteableType: String!) {
    notes(noteableId: $noteableId, noteableType: $noteableType, reverse: true, sortKey: "createdAt") {
      edges {
        node {
          id
          content
          createdAt
          author {
            id
            email
          }
        }
      }
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation createNote($noteableId: ID!, $noteableType: String!, $content: String!) {
    note {
      create(noteableId: $noteableId, noteableType: $noteableType, content: $content) {
        note {
          id
          content
          author {
            id
            email
          }
        }
      }
    }
  }
`;
