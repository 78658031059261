import React, { useReducer, useState } from "react";
import gql from "graphql-tag";
import { graphql, Query } from "react-apollo";
import { connectionToArray } from "../../utils/graphql";
import { withRouter, Link } from "react-router-dom";
import Table from "../../components/Table";
import Spinner from "../../components/Spinner";
import formStyles from "../../components/Form/styles.module.scss";
import { SearchField } from "../../components/Form/SearchField";
import { ListPageActions } from "../../components/ListPage";
import { PaginationReducer, PAGINATION_PAGE_SIZE } from "../../reducers/pagination";
import styles from "../styles.module.scss";

const APP_QUERY = gql`
  query PaginatedHosts($searchTerm: String, $first: Int, $after: String, $last: Int, $before: String ) {
    hosts(query: $searchTerm, first: $first, after: $after, last: $last, before: $before) {
      edges {
        node {
          id
          email
          firstName
          lastName
          phone
          status
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;


const HostsListPage = ({ history }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [state, dispatch] = useReducer(PaginationReducer, { cursor: null });
  let queryVars = { first: PAGINATION_PAGE_SIZE, searchTerm };

  if (state.cursor) {
    queryVars.after = state.cursor;
  }

  return (
    <div className={styles.listContainer}>
      <ListPageActions>
        <SearchField onSearch={setSearchTerm} />
        <Link to="/hosts/new" className={formStyles.button}>
          Add New Host
        </Link>
      </ListPageActions>
      <h3>Hosts</h3>

      <Query query={APP_QUERY} variables={queryVars}>
        {({ data, loading }) => {
          if (loading) {
            return <Spinner />;
          }
          const hosts = connectionToArray(data.hosts);
          const { pageInfo } = data.hosts;

          return (
            <React.Fragment>
              <Table
                data={hosts}
                onClick={row => history.push(`/hosts/${row.id}`)}
                headers={[
                  { key: "firstName", label: "First Name" },
                  { key: "lastName", label: "Last Name" },
                  { key: "email", label: "Email" },
                  { key: "phone", label: "Phone" },
                  { key: "status", label: "Status" }
                ]}
              />
              <div className={styles.buttonsContainer}>
                { pageInfo.hasPreviousPage && (
                  <button className={styles.navigateButton} onClick={() => dispatch({ type: "previous", pageInfo: pageInfo })}>
                    Prev
                  </button>
                )}
                { pageInfo.hasNextPage &&
                  <button className={styles.navigateButton} onClick={() => dispatch({ type: "next", pageInfo: pageInfo })}>
                    Next
                  </button>
                }
              </div>
            </React.Fragment>
          );
        }}
      </Query>
    </div>
  );
};

export default graphql(APP_QUERY)(withRouter(HostsListPage));
