import { Formik } from "formik";
import gql from "graphql-tag";
import { omit } from "lodash";
import React from "react";
import { Query } from "react-apollo";
import { withRouter } from "react-router-dom";
import NoteForm from "../../components/NoteForm";
import Spinner from "../../components/Spinner";
import InnerForm from "./Form";

const ShowingFragment = gql`
  fragment ShowingFragment on Showing {
    id
    host {
      firstName
      lastName
      email
      phone
    }
    guest {
      name
      email
      phone
    }
    calendlyActionUuid
    scheduledAt
    status
  }
`;

const APP_QUERY = gql`
  query getShowing($id: ID!) {
    showing(id: $id) {
      ...ShowingFragment
    }
  }
  ${ShowingFragment}
`;

const ShowingDetailPage = ({ match }) => (
  <Query query={APP_QUERY} variables={{ id: match.params.id }}>
    {({ data, loading }) => {
      if (loading) {
        return <Spinner />;
      }
      const { showing } = data;
      return (
        <div>
          <h3>Showing Detail</h3>
          <Formik initialValues={omit(showing, ["__typename", "id"])} render={() => <InnerForm showing={showing} />} />
          <NoteForm noteable={showing} noteableType="Showing" />
        </div>
      );
    }}
  </Query>
);

export default withRouter(ShowingDetailPage);
